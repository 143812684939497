import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  MenuItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import { GiCancel, GiConfirmed } from 'react-icons/gi';

const StyledTitle = styled(DialogTitle, {})({
  fontWeight: 'bold',
  alignSelf: 'center',
});

const StyledConfirm = styled(LoadingButton)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = Readonly<{
  disabled?: boolean,
  disableEntryFormat?: boolean,
  dialogTitle: string,
  dialogText: string,
  error?: boolean,
  errorMessage?: string,
  loading?: boolean,
  handleCancel: () => void,
  handleConfirm: () => void,
  open: boolean,
  selectOptions?: string[],
  setError?: (bool: boolean) => void,
  setValue: (value: string) => void,
  value: string,
  valueIcon?: JSX.Element,
}>;

// Entry dialog is a dialog pop-up component that is either a drop down select or
// text entry with a title, description text, entry method and variable icons.
function EntryDialog(props: Props) {
  const {
    disabled,
    disableEntryFormat,
    dialogTitle,
    dialogText,
    error,
    errorMessage,
    loading,
    handleCancel,
    handleConfirm,
    open,
    selectOptions,
    setError,
    setValue,
    value,
    valueIcon,
  } = props;

  const handleClose = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleCancel();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClose(event, reason)}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle>{dialogTitle}</StyledTitle>
      <DialogContent>

        <DialogContentText sx={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>{dialogText}</DialogContentText>

        {selectOptions ? (
          <TextField
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            id={`${dialogTitle}-entry`}
            label={`${dialogTitle}`}
            variant="outlined"
            color="primary"
            value={value || 'none'}
            helperText={error ? errorMessage : ''}
            error={error}
            onChange={(event) => {
              if (error && setError) setError(false);
              setValue(event.target.value);
            }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  {valueIcon}
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="none" disabled>Select one of the below</MenuItem>
            {selectOptions.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            fullWidth
            id={`${dialogTitle}-entry`}
            label={`${dialogTitle}`}
            variant="outlined"
            color="primary"
            value={value}
            helperText={error ? errorMessage : ''}
            error={error}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  {valueIcon}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              if (error && setError) setError(false);
              if (disableEntryFormat) {
                setValue(event.target.value);
              } else {
                setValue(event.target.value.toUpperCase());
              }
            }}
          />
        )}

      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleCancel}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          loading={loading}
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={handleConfirm}
          disabled={error || value === '' || disabled}
        >
          <Typography variant="body2">NEXT</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default EntryDialog;
