import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiConfirmed } from 'react-icons/gi';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '20px',
  paddingLeft: '20px',
  paddingRight: '20px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = Readonly<{
  dialogTitle: string,
  dialogText?: string,
  open: boolean,
  handleOk: () => void,
  setOpen: (toggle: boolean) => void,
}>;

function AlertDialog(props: Props) {
  const {
    dialogTitle, dialogText, open, handleOk, setOpen,
  } = props;

  const handleClose = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    setOpen(false);
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClose(event, reason)}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">{dialogTitle}</StyledTitle>
      {dialogText ? (
        <DialogContent>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{dialogText}</Typography>
          </Grid>
        </DialogContent>
      ) : null}

      <DialogActions>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={handleOk}
        >
          <Typography variant="body2">OK</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
