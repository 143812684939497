import React from 'react';

import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel } from 'react-icons/gi';
import { FaEdit } from 'react-icons/fa';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isEditing',
}) <{ isEditing?: boolean }>(({ theme, isEditing }) => ({
  height: '45px',
  position: 'inherit',
  width: '110px',
  backgroundColor: isEditing ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isEditing ? theme.palette.warning.main : theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

type Props = Readonly<{
  disabled?: boolean,
  onClick: () => void,
  isEditing?: boolean,
}>;

// Edit button used throughout portal. Optional isEditing will switch button to a
// cancel editing variant
function EditButton(props: Props) {
  const { disabled, onClick, isEditing = false } = props;

  return (
    <StyledButton
      disabled={disabled}
      isEditing={isEditing}
      aria-label="edit"
      variant="contained"
      startIcon={isEditing ? <GiCancel /> : <FaEdit />}
      onClick={onClick}
    >
      <Typography variant="body2">{isEditing ? 'CANCEL' : 'EDIT'}</Typography>
    </StyledButton>
  );
}

export default EditButton;
