import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiFillBug } from 'react-icons/ai';

const StyledDialog = styled(DialogContent, {})({
  height: '160px',
  display: 'flex',
  alignItems: 'center',
});

const StyledTitle = styled(DialogTitle, {})({
  fontWeight: 'bold',
  alignSelf: 'center',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = Readonly<{
  dialogTitle: string,
  dialogText?: string,
  open: boolean,
  handleClose: () => void,
}>;

function ErrorDialog(props: Props) {
  const {
    dialogTitle, dialogText, open, handleClose,
  } = props;

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle>{dialogTitle}</StyledTitle>
      {dialogText ? (
        <StyledDialog>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="body1">{dialogText}</Typography>
          </Grid>
        </StyledDialog>
      ) : null}
      <DialogActions>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<AiFillBug />}
          onClick={handleClose}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
