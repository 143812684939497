import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const DivRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
});

const StyledStatus = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLive',
}) <{ isLive?: boolean }>(({ theme, isLive }) => ({
  color: isLive ? theme.palette.secondary.main : theme.palette.warning.main,
  marginLeft: '10px',
}));

type Props = Readonly<{
  isLive: boolean,
}>;

function LiveStatusTitle(props: Props) {
  const { isLive } = props;

  return (
    <DivRow>
      <Typography variant="h1">Status : </Typography>
      <StyledStatus isLive={isLive} variant="h1">{isLive ? 'Live' : 'Not Live'}</StyledStatus>
    </DivRow>
  );
}

export default LiveStatusTitle;
