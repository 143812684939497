import React from 'react';

import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

import LiveStatusTitle from './LiveStatusTitle';

const StatusDiv = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
});

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isLive',
}) <{ isLive?: boolean }>(({ theme, isLive }) => ({
  height: '45px',
  width: '110px',
  position: 'inherit',
  backgroundColor: isLive ? theme.palette.warning.main : theme.palette.secondary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: isLive ? theme.palette.warning.main : theme.palette.secondary.main,
    color: theme.palette.common.white,
    border: '0px solid',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.4)',
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

type Props = Readonly<{
  disabled: boolean,
  isLive: boolean,
  onActivate: () => void;
  onDeactivate: () => void;
}>;

function LiveToggleButton(props: Props) {
  const {
    disabled,
    isLive,
    onActivate,
    onDeactivate,
  } = props;

  return (
    <StatusDiv>
      <LiveStatusTitle isLive={isLive} />
      {isLive ? (
        <StyledButton
          aria-label="deactivate"
          variant="contained"
          onClick={onDeactivate}
          isLive={isLive}
          disabled={disabled}
        >
          <Typography variant="body2">DEACTIVATE</Typography>
        </StyledButton>
      ) : (
        <StyledButton
          aria-label="activate"
          variant="contained"
          onClick={onActivate}
          isLive={isLive}
        >
          <Typography variant="body2">ACTIVATE</Typography>
        </StyledButton>
      )}
    </StatusDiv>
  );
}

export default LiveToggleButton;
