import React from 'react';

import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { IoChevronBackCircle } from 'react-icons/io5';

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '110px',
  backgroundColor: theme.palette.primary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

type Props = Readonly<{
  onClick: () => void;
}>;

// Simple styled back button used throughout portal.
function BackButton(props: Props) {
  const { onClick } = props;

  return (
    <StyledButton
      aria-label="back"
      variant="contained"
      startIcon={<IoChevronBackCircle />}
      onClick={() => onClick()}
    >
      <Typography variant="body2">BACK</Typography>
    </StyledButton>
  );
}

export default BackButton;
