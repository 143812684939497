import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';

import {
  TextField,
  Autocomplete,
  Typography,
  Box,
} from '@mui/material';

type AddressProps = Readonly<{
  handleAddress: (googleAddress: any, googleCoordinates: { lat: number, lng: number }) => void,
}>;

// Entry used for component for address entry
function AddressEntry(props: AddressProps) {
  const { handleAddress } = props;

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  // on select pull place id and use it to get geo-coordinates
  const handleSelect = async (location: any) => {
    if (status === 'OK') {
      clearSuggestions();
      const placeId = location.place_id;
      const results = await getGeocode({ placeId });
      const { lat, lng } = await getLatLng(results[0]);
      handleAddress(location, { lat, lng });
    }
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={data}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.description}
      onChange={(e, location) => {
        if (location !== null) {
          handleSelect(location);
        }
      }}
      renderOption={(prop, option) => (
        <Box component="li" {...prop}>
          <Typography variant="body1">{option.description}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="New Address"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}

// requires funcs to set coordinated, address, short address and place ID
type Props = Readonly<{
  setCoordinates: (coordinates: { lat: number, lng: number }) => void,
  setAddress: (address: string) => void,
  setShortAddress: (shortAddress: string) => void,
  setGooglePlaceId: (googlePlaceId: string) => void,
}>;

const libraries: Array<'places'> = ['places'];

// Component that integrates with google place api. Will give you address suggestions based on the
// string entered. Will return the address and the coordinates.
function PlacesAutoComplete(props: Props) {
  const {
    setCoordinates,
    setAddress,
    setShortAddress,
    setGooglePlaceId,
  } = props;

  // use api loader to check if place integration has loaded. only render if loaded
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries,
  });

  // on address select set address information
  const handleAddress = (googleAddress: any, googleCoordinates: { lat: number, lng: number }) => {
    setAddress(googleAddress.description);
    setShortAddress(googleAddress.structured_formatting.main_text);
    setGooglePlaceId(googleAddress.place_id);
    setCoordinates(googleCoordinates);
  };

  return (
    <div>
      {isLoaded ? (
        <AddressEntry handleAddress={handleAddress} />
      ) : null}
    </div>
  );
}

export default PlacesAutoComplete;
